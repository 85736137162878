import React, { useMemo } from "react"
import PageRoot from "../../components/PageRoot"
import { useTranslation } from "react-i18next"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { Col } from "antd"
import { graphql } from "gatsby"
import HeroHeader from "../../components/HeroHeader"
import AtsGrid from "../../components/AtsGrid"
import { renderCarouselItem } from "../../utils"

const OnDemandMasters = ({ data }) => {
  const { t } = useTranslation()

  const { bundles } = data.ats
  const mostRecentMaster = [bundles[0]]

  const masterHeroHeading = useMemo(() => {
    return (
      <HeroHeader
        className={"on-demand-hero-header"}
        dataSource={mostRecentMaster}
        showSlider={false}
      />
    )
  }, [bundles])

  const onDemandMastersRow = useMemo(() => {
    return (
      <AtsGrid
        title={t("label:onDemandMasters")}
        dataSource={bundles}
        renderItem={renderCarouselItem}
        emptyItem={t("message:noOnDemandMasters")}
        colSettings={{
          xs: 24,
          md: 12,
          lg: 8,
        }}
      />
    )
  }, [bundles])

  return (
    <PageRoot title={t("label:onDemand")}>
      {masterHeroHeading}
      <ContainerWithPadding size="full" style={{ paddingTop: 0 }}>
        <Col span={24}>
          <div className={"on-demand-masters-grid-wrapper"}>
            <div className={"on-demand-masters-grid"}>{onDemandMastersRow}</div>
          </div>
        </Col>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query onDemandMastersQuery {
    ats {
      bundles(
        order_by: { created_at: desc }
        where: {
          _and: { state: { _eq: "PUBLISHED" }, course_type: { _eq: "MASTER" } }
        }
      ) {
        course_type
        description
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        ...PreviewCardBundle
      }
    }
  }
`

export default OnDemandMasters
